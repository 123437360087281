import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Views,  } from "react-big-calendar";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
   // Customizable Area End
}

export interface S {
  // Customizable Area Start
  view: any;
  events: any;
  selectedView:any;
  rides:any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MyTasksController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      view: Views.WEEK,
      events: [
        {
          title: "Meeting",
          start: new Date(),
          end: new Date(new Date().setHours(new Date().getHours() + 1)),
          allDay: false,        
        },],
        selectedView:Views.DAY,
        rides:[]
    };    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        if (apiRequestCallId === this.getDataApiCallId) {
          const updatedRides = this.formatDateAndTime(responseJson.data);
         this.setState({rides:updatedRides})

        }   
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getRides();    
  }
  navigateToEditRide = () => {
    localStorage.setItem('redirectfrom','TripHistoryRider')
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "SideMenuBasic");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
}
  formatDateAndTime = (data:any) => {
    return data.map((item:any) => {
      const dateObj = new Date(`${item.attributes.date}T${item.attributes.time.split("T")[1]}`);
      
      const formattedDate = dateObj.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      }) + ',';
  
      const formattedTime = dateObj.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
   const events = data.map((item:any) => {
      const dateObj = new Date(`${item.attributes.date}T${item.attributes.time.split("T")[1]}`);
      return {
        id: item.id,
        title: `Ride Appointment`,
        start: dateObj,
        end: new Date(dateObj.getTime() + 60 * 60 * 1000), // Adding 1 hour for event duration
        allDay: false,
      };
    });

    this.setState({ events });
      return {
        ...item,
        status: "Scheduled",
        attributes: {
          ...item.attributes,
          formattedDate,
          formattedTime,
        },
      };
    });
    
  };
  getRides=()=>{
    let token:any =localStorage.getItem('token')
    const header = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_tasks/task_lists/scheduled_rides'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStatusStyles = (status:any) => {
    switch (status) {
      case "In Progress":
        return {
          backgroundColor: "#FEF3C7",
          color: "#D97706"
        };
      case "Scheduled":
        return {
          backgroundColor: "#DBEAFE",
          color: "#2563EB"
        };
      case "Completed":
        return {
          backgroundColor: "#D1F7C4",  // Light green
          color: "#006400"  // Dark green
        };
      default:
        return {
          backgroundColor: "#FFFFFF",  // Default to white if status is unknown
          color: "#000000"  // Default to black text
        };
    }
  };
  // Customizable Area End
}